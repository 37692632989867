import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFetchStates, useFetchDistrictsByState } from "geodata-utils";

const SelectCityState = ({ onStateChange, onDistrictChange, gettingInitialState, gettingInitialDistrict }) => {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const states = useFetchStates();
  const districts = useFetchDistrictsByState(selectedState);

 

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption.value);
    setSelectedDistrict(null); 
    onStateChange(selectedOption.value); 
  };

  const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption.value);
    onDistrictChange(selectedOption.value);
  };

  const stateOptions = states.map(state => ({ value: state, label: state }));
  const districtOptions = districts.map(district => ({ value: district, label: district }));

  return (
    <div className="">
      <div className="w-full">
       <div className="flex items-center">
       <p className="text-[#074173] font-semibold">Initial State - </p>
        <p className="ml-2">
        {gettingInitialState}
        </p>
       </div>
        <p className="block text-[#074173] font-bold mb-2">Select State</p>
        <Select
          options={stateOptions}
          value={stateOptions.find(option => option.value === selectedState)}
          onChange={handleStateChange}
          placeholder="Select State"
          className="block w-full"
        />
      </div>

      <div className="mt-3 w-full">
     <div className="flex items-center">
     <p className="text-[#074173] font-semibold">Initial District - </p>
      <p className="ml-2">
      {gettingInitialDistrict}
      </p>
     </div>

        <p className="block text-[#074173] font-bold mb-2">Select District</p>
        <Select
          options={districtOptions}
          value={districtOptions.find(option => option.value === selectedDistrict)}
          onChange={handleDistrictChange}
          placeholder="Select District"
          className="block w-full"
          isDisabled={!selectedState} 
        />
      </div>
    </div>
  );
};

export default SelectCityState;
