import React, { useEffect, useRef, useState } from "react";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { MdGroupAdd } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function AdminDetailedLocation() {
  const { id } = useParams();
  const [locationDetails, setLocationDetails] = useState(null);
  const [openingMoreImg, setOpeningMoreImg] = useState(null);
  const [activeSection, setActiveSection] = useState("description");
  const sliderRef = useRef(null);

  const [settings] = useState({
    dots: true,
    infinite: false,
    slideToShow: 1,
    slideToScroll: 1,
    speed: 60,
    autoplay: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (sliderRef.current) {
        if (event.key === "ArrowLeft") {
          sliderRef.current.slickPrev();
        } else if (event.key === "ArrowRight") {
          sliderRef.current.slickNext();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const navigate = useNavigate();

  async function getLocationDetails() {
    const querySnapshot = await getDocs(collection(db, "adding_location"));
    const multipleArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const selectedLocation = multipleArray.find(
      (location) => location.id === id
    );
    setLocationDetails(selectedLocation);
    console.log(multipleArray);
  }

  useEffect(() => {
    getLocationDetails();
  }, []);

  return (
    <div>
      <div className="mb-20">
        <button
          onClick={() => {
            navigate("/AdminDashboard");
          }}
          className="bg-red-500 sm:mt-5 sm:ml-5 text-white mt-2 ml-2 px-3 rounded py-1"
        >
          Back
        </button>
        <div className="flex justify-center">
          {locationDetails && (
            <div className="md:flex  sm:ml-0">
              <div>
                <div className="flex items-center">
                  <p className="text-[#074173] text-xl mr-1 sm:text-4xl  sm:mb-3 font-bold">
                    {locationDetails.location_name},
                  </p>
                 
                    <p className="sm:mt-3 mt-0 opacity-30 text-[#074173] font-semibold sm:text-xl">
                      {locationDetails.state}
                    </p>
                </div>
                <img
                  className="sm:h-[400px] sm:rounded sm:w-[650px] h-[200px] rounded w-[330px]"
                  src={locationDetails.image}
                />

                {openingMoreImg && (
                  <div className="bg-black flex justify-center items-center fixed inset-0 bg-opacity-70">
                    <div className="md:mt-0 sm:w-[500px] md:w-[820px] w-[320px] rounded sm:rounded-xl   sm:p-3">
                     <div className="flex justify-end">
                     <button
                        onClick={() => {
                          setOpeningMoreImg(false);
                        }}
                        className="bg-red-500 px-2  text-white"
                      >
                        X
                      </button>
                     </div>
                      <Slider ref={sliderRef} {...settings}>
                        <img
                          src={locationDetails.image}
                          className="md:h-[450px] sm:h-[350px] h-52"
                        />
                        {locationDetails.additionalImages.map(
                          (addImg, index) => (
                            <img
                              key={index}
                              src={addImg}
                              className="md:h-[450px] sm:h-[350px] h-52"
                            />
                          )
                        )}
                      </Slider>
                    </div>
                  </div>
                )}

                <p className="text-[#074173] mt-3 text-center mb-2 font-bold">
                  more photos
                </p>
                <div className="sm:grid grid overflow-auto h-[100px] w-[260px] sm:ml-0 ml-9 sm:w-auto grid-cols-2 gap-3 sm:grid-cols-4">
                  {locationDetails.additionalImages.map((imageUrl) => (
                    <img
                      onClick={() => {
                        setOpeningMoreImg(imageUrl);
                      }}
                      src={imageUrl}
                      className="h-[100px] rounded w-[120px]"
                    />
                  ))}
                </div>
              </div>

              <div className="mt-5 sm:mt-[85px] md:ml-10  lg:ml-20">
                <div className="sm:flex">
                  <div className="">
                    <p className="text-[#074173] text-xl font-bold">
                      Location Details
                    </p>
                    <div className="sm:flex mb-2">
                      <div className="">
                        <p className="text-[#074173] font-semibold">
                          Category:
                        </p>
                        <div className="ml-1">
                          {locationDetails.locationCategory.map(
                            (cat, index) => (
                              <li key={index}>{cat}</li>
                            )
                          )}
                        </div>
                      </div>

                      <div className="sm:ml-20 ml-0">
                        <p className="text-[#074173] font-semibold">
                          Suitable For:
                        </p>
                        <div className="ml-1">
                          {locationDetails.suitableFor.map((suit, index) => (
                            <li key={index}>{suit}</li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <p className="text-[#074173] font-semibold">
                        Staying Facility:
                      </p>
                      <p className="ml-1">
                        {locationDetails.staying_facilities}
                      </p>
                    </div>

                    <div className="flex">
                      <p className="text-[#074173] font-semibold">
                        Property Type:
                      </p>
                      <p className="ml-1">{locationDetails.location_type}</p>
                    </div>

                    <div className="flex">
                      <p className="text-[#074173] font-semibold">
                        Crowd Allowed:
                      </p>
                      <p className="ml-1 flex items-center">
                        {locationDetails.crowd_allowed}{" "}
                        <MdGroupAdd className="ml-1 text-lg" />
                      </p>
                    </div>

                    <div className="">
                      <p className="text-[#074173] font-semibold">
                        Location Link:
                      </p>
                      <p className="ml-1 w-40 sm:w-auto  flex items-center">
                        {locationDetails.location_link}{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <p className="text-[#074173] mb-3 mt-8 text-xl font-bold">
                  Additional Details
                </p>
                <div className="sm:flex  sm:w-[440px] sm:justify-between">
                  <div>
                    <button
                      className={`${
                        activeSection === "description"
                          ? "bg-[#074173] text-white"
                          : "bg-gray-300  text-[#074173] font-semibold"
                      } rounded px-3 py-1 `}
                      onClick={() => setActiveSection("description")}
                    >
                      Description
                    </button>

                    <div className="sm:hidden">
                      {activeSection === "description" && (
                        <div>
                          <p className="text-[#074173] mb-1 text-xl font-bold">
                            Description
                          </p>
                          <p className="text-justify sm:w-[500px] md:w-[450px] w-[330px] lg:w-[500px]">
                            {locationDetails.location_desciption}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      className={`${
                        activeSection === "publicFacility"
                          ? "bg-[#074173] sm:mt-0 mt-2 text-white"
                          : "bg-gray-300 sm:mt-0 mt-2 text-[#074173] font-semibold"
                      } rounded px-3 py-1`}
                      onClick={() => setActiveSection("publicFacility")}
                    >
                      Public Facility
                    </button>

                    <div className="sm:hidden">
                      {activeSection === "publicFacility" && (
                        <div>
                          <div className="sm:flex">
                            <div>
                              <p className="text-[#074173] text-lg font-bold">
                                Nearby Airport
                              </p>
                              <div>
                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Name:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.airport_name}
                                  </p>
                                </div>

                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Km:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.airport_km} km
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="md:ml-10 lg:ml-[80px]  ml-0">
                              <p className="text-[#074173] text-lg font-bold">
                                Nearby Railway Station
                              </p>
                              <div>
                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Name:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.station_name}
                                  </p>
                                </div>

                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Km:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.station_km} km
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-2 ">
                            <p className="text-[#074173]  text-lg font-bold">
                              Nearby Bus Stop
                            </p>
                            <div>
                              <div className="flex">
                                <p className="text-[#074173] font-semibold">
                                  Name:
                                </p>
                                <p className="ml-1">
                                  {locationDetails.stop_name}
                                </p>
                              </div>

                              <div className="flex">
                                <p className="text-[#074173] font-semibold">
                                  Km:
                                </p>
                                <p className="ml-1">
                                  {locationDetails.stop_km} km
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      className={`${
                        activeSection === "terms"
                          ? "bg-[#074173] sm:mt-0 mt-2 text-white"
                          : "bg-gray-300 sm:mt-0 mt-2 text-[#074173] font-semibold"
                      } rounded px-3 py-1`}
                      onClick={() => setActiveSection("terms")}
                    >
                      T&C
                    </button>

                    <div className="sm:hidden">
                      {activeSection === "terms" && (
                        <div
                          style={{
                            overflow: "auto",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                          className=" w-80 sm:w-[500px] h-60 "
                        >
                          <p className="text-[#074173] mb-1 text-xl font-bold">
                            Terms & Conditions
                          </p>
                          <p className="text-justify">
                            {locationDetails.termsAndCondition.map((terms) => (
                              <div>
                                <li>{terms}</li>
                              </div>
                            ))}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    className={`${
                      activeSection === "remarks"
                        ? "bg-[#074173] sm:mt-0 mt-2 text-white"
                        : "bg-gray-300 sm:mt-0 mt-2 text-[#074173] font-semibold"
                    } rounded px-3 py-1 `}
                    onClick={() => setActiveSection("remarks")}
                  >
                    Remarks
                  </button>
                </div>

                <div className="mt-3">
                  {activeSection === "description" && (
                    <div className="sm:block hidden">
                      <p className="text-[#074173] mb-1 text-xl font-bold">
                        Description
                      </p>
                      <p className="text-justify sm:w-[500px] md:w-[420px] w-[330px] lg:w-[500px]">
                        {locationDetails.location_desciption}
                      </p>
                    </div>
                  )}
                  {activeSection === "publicFacility" && (
                    <div className="sm:block hidden">
                      <div className="sm:flex">
                        <div>
                          <p className="text-[#074173] text-lg font-bold">
                            Nearby Airport
                          </p>
                          <div>
                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Name:
                              </p>
                              <p className="ml-1">
                                {locationDetails.airport_name}
                              </p>
                            </div>

                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Km:
                              </p>
                              <p className="ml-1">
                                {locationDetails.airport_km} km
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="md:ml-5 lg:ml-[80px]  ml-0">
                          <p className="text-[#074173] text-lg font-bold">
                            Nearby Railway Station
                          </p>
                          <div>
                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Name:
                              </p>
                              <p className="ml-1">
                                {locationDetails.station_name}
                              </p>
                            </div>

                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Km:
                              </p>
                              <p className="ml-1">
                                {locationDetails.station_km} km
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 ">
                        <p className="text-[#074173]  text-lg font-bold">
                          Nearby Bus Stop
                        </p>
                        <div>
                          <div className="flex">
                            <p className="text-[#074173] font-semibold">
                              Name:
                            </p>
                            <p className="ml-1">{locationDetails.stop_name}</p>
                          </div>

                          <div className="flex">
                            <p className="text-[#074173] font-semibold">Km:</p>
                            <p className="ml-1">{locationDetails.stop_km} km</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeSection === "terms" && (
                    <div
                      style={{
                        overflow: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                      className="hidden sm:block w-80 sm:w-[500px] h-60"  
                    >
                      <p className="text-[#074173] mb-1 text-xl font-bold">
                        Terms & Conditions
                      </p>

                      <p className="text-justify">
                        {locationDetails.termsAndCondition.map((terms) => (
                          <div>
                            <li>{terms}</li>
                          </div>
                        ))}
                      </p>
                    </div>
                  )}
                  {activeSection === "remarks" && (
                    <div
                    style={{
                      overflow: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    className=" w-80 sm:w-[500px] h-60"
                    >
                      <p className="text-[#074173] mb-1 text-xl font-bold">
                        Remarks
                      </p>
                      <p className="text-justify">
                        {locationDetails.remarks.map((rem) => (
                          <div>
                            <li>{rem}</li>
                          </div>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDetailedLocation;
