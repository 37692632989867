import React, { useState } from "react";
import GeneralEnquiry from "./GeneralEnquiry";
import { FaPhoneFlip } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import img1 from "../Components/dark theme logo (2).png";
import img2 from "../Components/Location Dekho (1).png";
import { Link } from "react-scroll";
import img3 from "../Components/dark theme logo (2).png";
import Footer from "./Footer";

function ContactUs() {
  const [shrinkingFilters, setShrinkingFilters] = useState(false);
  const location = useLocation();
  const navigating = useNavigate();

  const isContactUs = location.pathname == "/ContactUs";

  return (
    <div>
      <div className="fixed top-0 z-50 w-full sm:flex">
        <div className="flex bg-white p-1 justify-between items-center">
          <img
            className="h-[35px] block sm:hidden rounded w-[125px]"
            src={img3}
            alt="Logo"
          />
          <button
            className={`sm:hidden rounded text-white bg-[#074173] p-1`}
            onClick={() => setShrinkingFilters(!shrinkingFilters)}
          >
            <FaBars size={15} />
          </button>
        </div>

        <div
          className={`${
            shrinkingFilters ? "block" : "hidden"
          } sm:flex sm:items-center sm:justify-between z-50 sm:mt-0 fixed top-0 bg-[#074173] w-full text-center p-2 sm:py-2 sm:px-5 text-white sm:text-[#074173] sm:bg-white shadow font-[600] sm:text-xl sm:w-full text-base`}
        >
          <div>
            <button
              onClick={() => setShrinkingFilters(!shrinkingFilters)}
              className="bg-white sm:hidden z-50 block rounded text-[#074173]"
            >
              <FaTimes size={24} />
            </button>
            <img
              className="h-[50px] hidden sm:block rounded w-[200px]"
              src={img1}
              alt="Logo"
            />
            <img
              className="h-[50px] block sm:hidden rounded ml-10 w-[230px]"
              src={img2}
              alt="Logo"
            />
          </div>
          <div className="sm:flex sm:items-center sm:w-[350px] md:w-[400px] sm:justify-between">
            <p
            onClick={()=>{
              navigating('/')
            }}
            >
                Home
            </p>
            <p
              onClick={() => {
                navigating("/AboutUs");
              }}
            >
              About Us
            </p>
           
            <p className={isContactUs ? "border-b-2 sm:mx-0 mx-32 border-white sm:border-[#074173]" : ""}>
              <Link to="general-enquiry" smooth={true} duration={700}>
                Contact Us
              </Link>
            </p>
          </div>
        </div>
      </div>


      <p className="mt-20 text-center text-[#074173] text-3xl sm:text-4xl md:text-4xl font-bold">Reach out and let's brew up a conversation!</p>
      <div className="mt-20">
        <p className="md:text-2xl text-xl text-[#074173] mb-3 text-center font-bold">
          Want to rent your Location?
        </p>
        <p className="sm:ml-0 sm:mr-0 mr-5 ml-5 sm:w-full sm:text-xl text-center font-semibold">
          Ready to make your property a star? List it with us for film and TV
          location rentals!
        </p>
        <div className="flex mt-2 justify-center">
          <a
            href="tel:9998973825"
            className="bg-[#074173] rounded px-3 py-1 text-white"
          >
            <div className="flex items-center">
              <p>Call Now</p>
              <FaPhoneFlip className="ml-1" />
            </div>
          </a>
        </div>
      </div>

      <div id="general-enquiry" className="mt-20 mb-20">
        <GeneralEnquiry />
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
