import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFetchStates, useFetchDistrictsByState } from "geodata-utils";

const CityStateFiltering = ({ onStateChange, onDistrictChange, resetFilters }) => {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const states = useFetchStates();
  const districts = useFetchDistrictsByState(selectedState);

//  console.log(resetFilters)

  useEffect(() => {
    if (resetFilters == false) {
      setSelectedState(null);
      setSelectedDistrict(null);
    }
  }, [resetFilters]);

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption.value);
    setSelectedDistrict(null);
    onStateChange(selectedOption.value);
  };

  const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption.value);
    onDistrictChange(selectedOption.value);
  };

  const stateOptions = states.map((state) => ({ value: state, label: state }));
  const districtOptions = districts.map((district) => ({
    value: district,
    label: district,
  }));

  return (
    <div className="filter-container mt-2 md:flex md:items-center sm:flex lg:ml-7 ml-0">
      <div className="w-[200px]">
        <Select
          options={stateOptions}
          value={stateOptions.find((option) => option.value === selectedState)}
          onChange={handleStateChange}
          placeholder="Select State"
          className="block w-full"
        />
      </div>

      <div className="w-[200px] sm:ml-3 ml-0">
        <Select
          options={districtOptions}
          value={districtOptions.find(
            (option) => option.value === selectedDistrict
          )}
          onChange={handleDistrictChange}
          placeholder="Select District"
          className="block w-full"
          isDisabled={!selectedState}
        />
      </div>
    </div>
  );
};

export default CityStateFiltering;
