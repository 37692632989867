import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import img1 from '../Components/Screenshot 2024-06-14 164834.png'

function Footer() {
  return (
    <footer className="bg-[#074173] w-full  py-4 text-white">
<div className="flex justify-center">
<img 
className="h-[60px] rounded mb-3 w-[250px]"
src={img1}/>
</div>
      <p className="text-center text-base md:text-lg font-roboto-condensed mb-5">
        Lights, camera, location! Find your filming spot today!
      </p>
      <hr className="text-[#074173] mb-5" />

      <div className="flex flex-col sm:flex-row sm:justify-center items-center">
        <div className="flex items-center mb-5 sm:mb-0 mr-10">
          <p className="font-[600]">Contact Us:</p>
          <FaPhoneAlt size={15} className="ml-2 mt-1 mr-1" />
          <p>9998973825</p>
        </div>

        <div className="flex items-center mb-5 sm:mb-0">
          <div className="mr-4 p-1 rounded-full">
            <Link to={'https://www.facebook.com/lineproductiongujarat?mibextid=kFxxJD'}>
              <FaFacebookF className="text-xl text-white" />
            </Link>
          </div>

          <div className="mr-4 p-1 rounded-full">
            <Link to={'https://x.com/?lang=en-in'}>
              <FaTwitter className="text-xl text-white" />
            </Link>
          </div>

          <div className="p-1 rounded-full">
            <Link to={'https://www.instagram.com/locationdekho.in?igsh=MW5iZnE0c2d5dXp4eg=='}>
              <GrInstagram className="text-xl text-white" />
            </Link>
          </div>
        </div>

        <div className="flex items-center mb-5 sm:mb-0 ml-0 sm:ml-10">
          <p className="font-[600]">Email:</p>
          <p className="ml-1">locationdekho1@gmail.com</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
