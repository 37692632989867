import React, { useEffect, useState } from "react";
import AdminNavbar from "./Admin-navbar";
import { Field, FieldArray, Form, Formik } from "formik";
import FormikInput from "../Components/FormikInput";
import SelectCityState from "./SelectCityState";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { toast } from "react-toastify";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import * as Yup from "yup";
import { FaPlus } from "react-icons/fa6";
import heic2any from "heic2any";
import { FaTrash } from "react-icons/fa";

function AddLocation() {
  const [locCategory, setLocCategory] = useState([]);
  const [suitable, setSuitable] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [locationImage, setLocationImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [submittingSuitable, setSubmittingSuitable] = useState([]);
  const [submittingCategory, setSubmittingCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    location_name: Yup.string()
      .required("Name is required")
      .min(6, "Name must have at least 6 characters"),

    station_km: Yup.string()
      .required("Station km is required")
      .min(1, "Station km should be entered."),

    airport_km: Yup.string()
      .required("Airport km is required")
      .min(1, "Airport km should be entered."),

    station_name: Yup.string()
      .min(6, "Station must have at least 6 characters")
      .required("Station is required"),

    stop_km: Yup.string()
      .min(1, "Stop must have at least 6 characters")
      .required("Stop is required"),

    stop_name: Yup.string()
      .min(6, "Stop must have at least 6 characters")
      .required("Stop is required"),

    airport_name: Yup.string()
      .min(6, "Airport name must have at least 6 characters")
      .required("Airport name is required"),

    location_desciption: Yup.string()
      .min(6, "Description must be written")
      .required("Description is required"),

    location_address: Yup.string()
      .min(15, "Address must be written")
      .required("Address is required"),

    location_link: Yup.string()
      .min(15, "Link should be written")
      .required("Link is required"),
  });

  useEffect(() => {
    const gettingLocationCategory = async () => {
      const querySnapshot = await getDocs(collection(db, "location_category"));
      const multipleArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLocCategory(multipleArray);
    };

    const gettingSuitableFor = async () => {
      const querySnapshot = await getDocs(collection(db, "suitable_for"));
      const multipleArray1 = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSuitable(multipleArray1);
    };

    gettingLocationCategory();
    gettingSuitableFor();
  }, []);

  const uploadFile = async (file) => {
    let fileToUpload = file;

    if (file.type === "image/heic" || file.type === "") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });

        fileToUpload = new File(
          [convertedBlob],
          `${file.name.split(".")[0]}.jpg`,
          {
            type: "image/jpeg",
          }
        );
      } catch (error) {
        return;
      }
    }

    const storage = getStorage();
    const storageRef = ref(storage, `lol/${fileToUpload.name}`);

    try {
      const uploadResult = await uploadBytes(storageRef, fileToUpload);

      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {}
  };

  const addingLocation = async (values, { resetForm }) => {
    setIsLoading(true);

    try {
      const stateName = selectedState;
      const districtName = selectedDistrict;
      const imageURL = await uploadFile(locationImage);
      const additionalImageURLs = await Promise.all(
        additionalImages.map((file) => uploadFile(file))
      );

      const formValuesWithDistrictState = {
        ...values,
        state: stateName,
        district: districtName,
        image: imageURL,
        suitableFor: submittingSuitable,
        locationCategory: submittingCategory,
        additionalImages: additionalImageURLs,
      };

      await addDoc(
        collection(db, "adding_location"),
        formValuesWithDistrictState
      );
      resetForm();
      toast.success("Location added successfully!!");
    } catch (error) {
      toast.error("Failed to add location");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="sm:flex">
      <AdminNavbar />
      <div className="ml-0 md:ml-60 lg:ml-[450px]  shadow-xl p-5 overflow-auto h-screen">
        <p className="text-3xl font-bold text-center text-[#074173]">
          Add Location
        </p>

        <div className="mt-4">
          <Formik
            initialValues={{
              location_name: "",
              location_desciption: "",
              stop_name: "",
              stop_km: "",
              station_name: "",
              station_km: "",
              airport_name: "",
              airport_km: "",
              location_link: "",
              crowd_allowed: "",
              staying_facilities: "",
              location_type: "",
              termsAndCondition: [""],
              location_address: "",
              remarks: [""],
            }}
            onSubmit={addingLocation}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="md:w-auto w-auto sm:w-auto p-2 md:ml-0 border-2">
                  <div className="sm:flex justify-center">
                    <div className="sm:w-auto rounded mr-0 w-auto sm:mr-[120px]">
                      <div className="mt-2">
                        <p className="block text-[#074173] font-bold mb-2">
                          Location Category
                        </p>

                        <div className="overflow-auto h-40">
                          {locCategory.map((loc) => (
                            <div className="text-[#074173]" key={loc.id}>
                              <input
                                className="mr-2"
                                type="checkbox"
                                value={loc.category}
                                onChange={(e) => {
                                  setSubmittingCategory([
                                    ...submittingCategory,
                                    e.target.value,
                                  ]);
                                }}
                              ></input>
                              {loc.category}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="mt-7">
                        <FormikInput
                          name="location_name"
                          label="Location Name"
                          placeholder="Enter Location Name"
                        />
                        <div className="mt-3">
                          <FormikInput
                            name="location_desciption"
                            label="Location Description"
                            placeholder="Enter Location Description"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-5">
                        <div className="mt-2">
                          <p className="block text-[#074173] font-bold mb-2">
                            Suitable for
                          </p>

                          <div className="overflow-auto h-40">
                            {suitable.map((suit) => (
                              <div className="text-[#074173]" key={suit.id}>
                                <input
                                  className="mr-2"
                                  type="checkbox"
                                  value={suit.suitable_for}
                                  onChange={(e) => {
                                    setSubmittingSuitable([
                                      ...submittingSuitable,
                                      e.target.value,
                                    ]);
                                  }}
                                ></input>
                                {suit.suitable_for}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className=" text-[#074173]">
                        <SelectCityState
                          onStateChange={(state) => setSelectedState(state)}
                          onDistrictChange={(district) =>
                            setSelectedDistrict(district)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <FormikInput
                      name="location_address"
                      placeholder="Enter Address"
                      type="text"
                      label="Address"
                    />

                    <div className="sm:flex md:mt-5 sm:px-7 sm:justify-between">
                      <FormikInput
                        name="location_link"
                        placeholder="Enter Location Link"
                        type="text"
                        label="Location Link"
                      />
                      <FormikInput
                        name="crowd_allowed"
                        placeholder="No of Crowd Allowed"
                        type="number"
                        label="No of Crowd Allowed"
                      />
                      <div>
                        <p className="block mt-3 text-[#074173] font-bold mb-1">
                          Staying Facilities:
                        </p>
                        <div className="flex items-center">
                          <div className="flex items-center">
                            <Field
                              type="radio"
                              name="staying_facilities"
                              value="Yes"
                            />
                            <label className="ml-2">Yes</label>
                          </div>
                          <div className="flex ml-3 items-center">
                            <Field
                              type="radio"
                              name="staying_facilities"
                              value="No"
                            />
                            <label className="ml-2">No</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div>
                        <FieldArray name="remarks">
                          {({ push, remove }) => (
                            <div>
                              <div className="flex  items-center">
                                <label className="block mb-1  font-bold text-[#074173]">
                                  Remarks
                                </label>
                                <button
                                  type="button"
                                  onClick={() => push("")}
                                  className="bg-[#074173] ml-3 p-1 text-lg text-[#FBFFFF] rounded-full"
                                >
                                  <FaPlus />
                                </button>
                              </div>
                              {values.remarks.map((requirement, index) => (
                                <div className="flex items-center justify-between">
                                  <div className="w-full">
                                    <FormikInput
                                      key={index}
                                      name={`remarks[${index}]`}
                                      placeholder={"Enter Remarks...."}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="text-red-500 pl-2"
                                  >
                                    <FaTrash />
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </div>

                      <div className="mt-3">
                        <FieldArray name="termsAndCondition">
                          {({ push, remove }) => (
                            <div>
                              <div className="flex  items-center">
                                <label className="block mb-1 font-bold text-[#074173]">
                                  Terms and Condition
                                </label>
                                <button
                                  type="button"
                                  onClick={() => push("")}
                                  className="bg-[#074173] ml-3 p-1 text-lg text-[#FBFFFF] rounded-full"
                                >
                                  <FaPlus />
                                </button>
                              </div>
                              {values.termsAndCondition.map(
                                (requirement, index) => (
                                <div className="flex items-center justify-between">
                                   <div className="w-full">
                                   <FormikInput
                                    key={index}
                                    name={`termsAndCondition[${index}]`}
                                    placeholder={
                                      "Enter Terms and Condition...."
                                    }
                                  />
                                   </div>
                                  <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="text-red-500 pl-2"
                                >
                                  <FaTrash />
                                </button>
                                </div>
                                )
                              )}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="block mt-3 text-[#074173] font-bold mb-1">
                      Location Type:
                    </p>
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <Field
                          type="radio"
                          name="location_type"
                          value="public"
                        />
                        <label className="ml-2">Public</label>
                      </div>
                      <div className="flex ml-3 items-center">
                        <Field
                          type="radio"
                          name="location_type"
                          value="private"
                        />
                        <label className="ml-2">Private</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:grid sm:grid sm:grid-cols-2 grid grid-cols-1 md:gap-3 md:grid-cols-3">
                  <div className="border-2 p-2 rounded">
                    <p className="block text-lg text-[#074173] font-bold">
                      Nearby Bus Stop
                    </p>
                    <FormikInput
                      name="stop_name"
                      placeholder="Bus Stop Name"
                      label="Stop Name"
                    />
                    <FormikInput
                      name="stop_km"
                      placeholder="Enter Km"
                      type="number"
                      label="Stop Km"
                    />
                  </div>
                  <div className="border-2 p-2 rounded">
                    <p className="block text-lg text-[#074173] font-bold">
                      Nearby Railway Station
                    </p>
                    <FormikInput
                      name="station_name"
                      placeholder="Station Name"
                      label="Station Name"
                    />
                    <FormikInput
                      name="station_km"
                      placeholder="Enter Km"
                      type="number"
                      label="Station Km"
                    />
                  </div>
                  <div className="border-2 p-2 rounded">
                    <p className="block text-lg text-[#074173] font-bold">
                      Nearby Airport
                    </p>
                    <FormikInput
                      name="airport_name"
                      placeholder="Airport Name"
                      label="Airport Name"
                    />
                    <FormikInput
                      name="airport_km"
                      placeholder="Enter Km"
                      type="number"
                      label="Airport Km"
                    />
                  </div>
                </div>
                <div className="border-2 p-2 mt-7">
                  <div className="sm:flex">
                    <div>
                      <p className="block text-[#074173] font-bold">
                        Upload Main Image
                      </p>
                      <input
                        className="ml-2"
                        type="file"
                        onChange={(e) => setLocationImage(e.target.files[0])}
                      />
                    </div>
                    <div>
                      <p className="block text-[#074173] font-bold">
                        Add More Images
                      </p>
                      <input
                        multiple
                        className="ml-2"
                        type="file"
                        onChange={(e) =>
                          setAdditionalImages(Array.from(e.target.files))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-auto">
                  <button
                    type="submit"
                    className={`bg-[#074173] text-white mb-5 mt-3 sm:px-[313px] px-auto p-2 rounded ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "wait location is uploading..."
                      : "Add Location"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddLocation;
